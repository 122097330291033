.bg2 {
  background-image: linear-gradient(
      180deg,
      rgba(0, 58, 91, 0.65) 0%,
      rgba(41, 196, 169, 0.7) 100%
    ),
    url("https://images.pond5.com/businessman-talking-business-phone-holding-footage-088258445_prevstill.jpeg");
  color: white;
  /* width: 100%; */
  height: 50vh;
  background-size: cover;
  background-position: 50% 20%;
  background-repeat: no-repeat;
}

.contain {
  padding: 30px;
  border-radius: 10px;
}

.serv2-heading {
  font-size: 4em;
}

.list-mwa-desc {
  background-color: aliceblue;
  text-align: left;
  padding-left: 30px;
  padding-right: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 18px;
}

.fa-check-circle {
  color: #16df7e;
  size: 0.5em;
  padding: 8px;
}

li {
  line-height: 2;
  text-align: left;
}

.ul-margin {
  margin-left: 120px;
}
@media only screen and (max-width: 768px) {
  .serv2-heading {
    font-size: 2em;
  }

  .ul-margin {
    margin-left: 0px;
  }
}
