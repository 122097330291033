@import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css);
:root{
  --color-secondary:#03d694;
  --color-primary:#16df7e;
}
body {
  margin: 0;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
    padding-top: 65px; 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* montserrat-regular - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/montserrat-v15-latin-regular.0eb53b51.eot); /* IE9 Compat Modes */
  src: local(''),
       url(/static/media/montserrat-v15-latin-regular.0eb53b51.eot?#iefix) format('embedded-opentype'), 
       url(/static/media/montserrat-v15-latin-regular.b71748ae.woff2) format('woff2'), 
       url(/static/media/montserrat-v15-latin-regular.0659a9f4.woff) format('woff'), 
       url(/static/media/montserrat-v15-latin-regular.fa100f9f.ttf) format('truetype'), 
       url(/static/media/montserrat-v15-latin-regular.22d8566c.svg#Montserrat) format('svg'); /* Legacy iOS */
}
/* montserrat-600 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: url(/static/media/montserrat-v15-latin-600.dc335c19.eot); /* IE9 Compat Modes */
  src: local(''),
       url(/static/media/montserrat-v15-latin-600.dc335c19.eot?#iefix) format('embedded-opentype'), 
       url(/static/media/montserrat-v15-latin-600.0480d2f8.woff2) format('woff2'), 
       url(/static/media/montserrat-v15-latin-600.b77863a3.woff) format('woff'), 
       url(/static/media/montserrat-v15-latin-600.562baba2.ttf) format('truetype'), 
       url(/static/media/montserrat-v15-latin-600.160f4d63.svg#Montserrat) format('svg'); /* Legacy iOS */
}

.navbar-custom {
  width: 100%;
  /* margin:auto; */
  padding-left: 10%;
  padding-right: 10%;
  background-color: white !important;
}

nav,
.dropdown-item {
  font-family: "Montserrat", Helvetica, Arial, Lucida, sans-serif;
  font-size: 14px;
  font-weight: bold !important;
  letter-spacing: 1px;
}
.get-started-button {
  float: right;
  background-color: #ef692b;
  color: white;
  border-radius: 8px;
  border-bottom: 4px #ba6036 solid;
  text-align: center;
  outline: none;
  border-top: none;
  border-left: none;
  border-right: none;
  font-size: 15px;
  font-weight: 600;
  padding-top: 10px;
  margin-left: 8.5px;
}
.get-started-button:hover {
  background-color: #ef7c46;
  border-bottom: 4px #ef7c46 solid;
  text-decoration: none;
  color: white;
  font-size: 15px;
  font-weight: 600;
}
@media screen and (max-width: 768px) {
  .get-started-button {
    float: left;
  }
}

@media screen and (max-width: 990px) {
  .navbar .nav-item:hover .dropdown-menu {
    display: block;
    transition: ease-in-out 0.2s;
  }
}
@media screen and (max-width: 990px) {
  .navbar .nav-item .dropdown-menu {
    border: none;
  }
  .navbar .nav-item .dropdown-item {
    padding-left: 20px;
    transform: translateY(0);
  }
}
.navbar .nav-item .dropdown-menu {
  margin-top: 0;
}

.dropdown-item.active,
.dropdown-item:active {
  text-decoration: none;
  font-weight: bold;
  background-color: white;
}

.centered{
    position: absolute;
    top: 50%; left: 50%;
    transform: translate(-50%,-50%);
}
p{
    /* font-family: 'Open Sans',Helvetica,Arial,Lucida,sans-serif; */
    font-weight: 400;
    line-height:1.8em;

}
custom-text{
    width: 60%;
    text-align: center;
    margin: auto;
}
.aboutus-H{
    background-image:linear-gradient(180deg,rgba(0,58,91,0.65) 0%,rgba(41,196,169,0.7) 100%),url(/static/media/aboutus_bg.2ae16046.jpg)!important;
}
.our-team{
    background-image:linear-gradient(180deg,rgba(0,58,91,0.65) 0%,rgba(41,196,169,0.7) 100%),url(/static/media/our-team.4f3717d6.jpg)!important;
}
.faq-bg{
    background-image:linear-gradient(180deg,rgba(0,58,91,0.65) 0%,rgba(41,196,169,0.7) 100%),url(/static/media/faq_bg.dc0de0e8.jpg)!important;
}
.contactus-bg{
    background-image:linear-gradient(180deg,rgba(0,58,91,0.65) 0%,rgba(41,196,169,0.7) 100%),url(/static/media/contactus-bg.7715d543.jpg)!important;
}
.aboutus-H,.our-team,.faq-bg,.contactus-bg{
    color:white;
    /* width: 100%; */
    height: 50vh;
    background-size: cover;
    background-position: 50% 20%;
    background-repeat: no-repeat;
}
.container-fluid{
    padding-left: 0ch;
    position: relative;
    padding-right: 0ch;
    margin-left: 0ch;
    margin-right: 0ch;
}
.subheading{
    margin-top: 20px;
    font-family: 'Open Sans',Helvetica,Arial,Lucida,sans-serif;
    font-weight: 300;
    /* line-height:1.8em; */
    width: 80%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

/* FontAwesome for working BootSnippet :> */
#team {
    background: #eee !important;
}




section {
    padding: 60px 0;
}

section .section-title {
    text-align: center;
    color: black;
    margin-bottom: 50px;
    text-transform: uppercase;
}

#team .card {
    border: none;
    background: #ffffff;
}

.image-flip:hover .backside,
.image-flip.hover .backside {
    transform: rotateY(0deg);
    border-radius: .25rem;
}

.image-flip:hover .frontside,
.image-flip.hover .frontside {
    transform: rotateY(180deg);
}

.mainflip {
    -webkit-transition: 1s;
    -webkit-transform-style: preserve-3d;
    -ms-transition: 1s;
    -moz-transition: 1s;
    -moz-transform: perspective(1000px);
    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transition: 1s;
    transform-style: preserve-3d;
    position: relative;
}

.frontside {
    position: relative;
    -webkit-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    z-index: 2;
    margin-bottom: 20px;
}

.backside {
    font-size: 0.9rem;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background: white;
    border: 1px solid rgba(0,0,0,.125);
    transform: rotateY(-180deg);
    box-shadow: 5px 7px 9px -4px rgb(158, 158, 158);
}

.frontside,
.backside {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: 1s;
    -webkit-transform-style: preserve-3d;
    -moz-transition: 1s;
    -moz-transform-style: preserve-3d;
    -o-transition: 1s;
    -o-transform-style: preserve-3d;
    -ms-transition: 1s;
    -ms-transform-style: preserve-3d;
    transition: 1s;
    transform-style: preserve-3d;
}

.frontside .card,
.backside .card {
    /* min-height: 312px; */
}

.backside .card a {
    font-size: 18px;
    color: var(--color-primary);
}

.frontside .card .card-title,
.backside .card .card-title {
    color: black !important;
}

.frontside .card .card-img-top {
    width: 70%;
    margin: 20px auto;
    height: auto;
    border-radius: 50%;
}
.backside .card{
    border:none;
    padding:20px;
    z-index: 100;
}
.card-text{
    /* overflow: scroll; */
    /* height: 80%; */
}
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.container-fluid {
  padding-left: 0ch;
  position: relative;
  padding-right: 0ch;
  margin-left: 0ch;
  margin-right: 0ch;
}
.connect-H {
  background-image: linear-gradient(
      180deg,
      rgba(0, 58, 91, 0.65) 0%,
      rgba(41, 196, 169, 0.7) 100%
    ),
    url(/static/media/homeBackground.6ed631ce.jpg) !important;
  color: white;
  /* width: 100%; */
  height: 80vh;
  background-size: cover;
  background-position: 50% 20%;
  background-repeat: no-repeat;
}

.btn-primary:hover {
  box-shadow: 0 8px 28px rgba(32, 107, 251, 0.45);
}

.btn-primary:focus {
  box-shadow: 0 8px 28px rgba(32, 107, 251, 0.45);
}
.btn-primary:not(:disabled):not(.disabled):active:focus {
  box-shadow: 0 8px 28px rgba(32, 107, 251, 0.45);
}

.btn-primary:disabled {
  box-shadow: none;
}

.col-sm-3 {
  padding: 10px;
}

.col-sm-4 {
  padding: 20px;
}

.col-sm-6 {
  padding: 20px;
}

.svg-container {
  padding: 15px;
}
.form-box {
  margin: auto;
  border: white;
  background-color: white;
  border-radius: 5px;
  padding-top: 1rem;
  padding-bottom: 1rem;
  box-shadow: 0 5px 18px 3px rgba(0, 0, 0, 0.1);
  /* box-shadow: 2px 2px 2px 2px grey; */
  /* padding-bottom: 50px; */
}

.form-control {
  width: 100%;

  /* float: center; */
  /* background-color: LightGrey; */
}

.form-group {
  padding-top: 8px;
  padding-bottom: 8px;
}

.home-form-head {
  text-align: left;
  border-left-style: solid;
  border-left-color: var(--color-primary);
  border-left-width: 5px;
  padding-left: 10px;
}

.home-form-content {
  text-align: left;
  padding: 20px;
}

@media only screen and (max-width: 768px) {
  .form-control {
    width: 100%;
  }

  .home-bg-content {
    font-size: 15px;
  }

  .home-slider .carousel-item .carousel-caption {
    top: 20% !important;
  }

  .home-slider .carousel-item .carousel-caption h2 {
    font-size: 24px;
  }

  .home-slider .carousel-item .carousel-caption p {
    font-size: 1rem;
  }
}

.home-slider .carousel-item::before {
  content: "";
  background-color: rgba(255, 255, 255, 0.6);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}
.home-slider .carousel-item span {
  color: var(--color-primary);
}
.home-slider .carousel-item h2,
p {
  color: black;
}
.home-slider .carousel-item h2 {
  font-size: 48px;
}
.home-slider .carousel-item h2:hover {
  color: black;
}
.home-slider .carousel-item p {
  font-size: 1.25rem;
}
.home-slider .carousel-indicators li {
  /* cursor: pointer; */
  background: var(--color-primary);
  width: 10px;
  height: 10px;
  border-radius: 100%;
  border-top: unset;
  border-bottom: unset;
}
.features-home i {
  color: var(--color-primary);
}
.icon-box a {
  color: #5f687b;
  text-transform: capitalize;
}
.icon-box a:hover {
  color: var(--color-primary);
  text-decoration: unset;
}
.icon-box h3 {
  font-weight: 500;
  margin-top: 15px;
  font-size: 20px;
}
.icon-box {
  padding: 40px 30px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  background: #fff;
  transition: all ease-in-out 0.3s;
  height: 100%;
}
.features-home {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.icon-box p {
  color: #545454;
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}
.counters span {
  font-size: 2.5rem;
  /* display: block; */
  color: var(--color-primary);
  font-weight: 600;
}
.counters {
  margin-top: 1rem;
}
.counters p {
  padding: 0;
  margin: 0 0 20px 0;
  /* font-family: "Raleway", sans-serif; */
  font-size: 14px;
  font-weight: 600;
}
.services-home .section-title {
  text-align: center;
  padding-bottom: 30px;
}
.services-home .section-title h2 {
  font-size: 2.4rem;
  font-weight: 600;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
}
.services-home {
  background-color: #f9f9fa;
}
p {
  line-height: 28px;
  margin-bottom: 15px;
  color: #666;
}
.services-home .section-title h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 50px;
  height: 3px;
  background: var(--color-primary);
  bottom: 0;
  left: calc(50% - 25px);
}

.underlined-subheading h2 {
  font-size: 2.4rem;
  font-weight: 500;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
}

.underlined-subheading h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 50px;
  height: 3px;
  background: var(--color-primary);
  bottom: 0;
  left: calc(50% - 25px);
}
.btn-primary {
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 8px 30px 10px 30px;
  border-radius: 4px;
  transition: 0.5s;
  color: #fff;
  background: #16df7e;
  box-shadow: 0 8px 28px rgba(22, 223, 126, 0.45);
  border: none;
}
/*Counter animation */

/* @property --num {
  syntax: '<integer>';
  initial-value: 0;
  inherits: false;
}

.counters span{
  animation: counter 5s 1 alternate ease-in-out;
  counter-reset: num var(--num);
  animation-fill-mode: forwards;
}
.counters span::after {
  content: counter(num);
}
.counters span::before{
  --data-content:attr(data-content);
}
@keyframes counter {
  from {
    --num: 0;
  }
  to {
    --num: var(--data-content);
  }
} */

.carousel-control-prev {
  color: rgb(122, 120, 120);
  transition: all 0.3s ease-in-out;
}
.carousel-control-next:active {
  color: rgb(122, 120, 120);
  transition: all 0.3s ease-in-out;
}
.carousel-control-prev:hover {
  color: var(--color-secondary);
}
.carousel-control-next:hover {
  color: var(--color-secondary);
}
.carousel-control-next {
  color: rgb(122, 120, 120);
  transition: all 0.3s ease-in-out;
}
.fas {
  font-weight: 900;
  font-size: 2rem;
}
.carousel-caption {
  top: 40% !important;
}
.carousel-item img {
  width: 100%;
  height: 60vh;
  object-fit: cover;
}
.carousel-item {
  flex: 1 0 24rem;
}
.people a {
  text-decoration: none;
  font-size: 16px;
  color: #ffffff;
  background: #1a54e7;
  padding: 10px 25px;
  font-weight: 600;
  transition: 0.3s;
  text-transform: capitalize;
}

.caption-footer {
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
@media only screen and (max-width: 600px) {
  .caption-footer {
    display: none;
  }
}
.slider p {
  color: white;
  font-size: 14px;
}
.slider a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}
.carousel-caption h2 {
  cursor: pointer;
}
.carousel-caption h2:hover {
  color: var(--color-secondary);
}

.blog-wrapper {
  position: relative;
  padding: 15px;
}
.blog-img img {
  width: 100%;
  height: 20.5rem;
  object-fit: cover;
}
.blog-content h3 a {
  letter-spacing: 0.02em;
  text-decoration: none;
  color: #000000;
  font-size: 20px;
  padding-top: 20px;
  display: block;
}
.blog-wrapper .tag-1 a {
  bottom: 40%;
  left: 0;
}
.tag-1 a {
  padding: 7px 21px;
  background: var(--color-secondary);
  text-decoration: none;
  color: #ffffff;
  font-weight: 600;
  position: absolute;
  bottom: 40%;
  left: 0;
  font-size: 14px;
  text-transform: capitalize;
}
.slider-date a {
  text-decoration: none;
  text-transform: capitalize;
  font-weight: 600;
  color: #505050;
}

.fa-ul {
  margin-left: 0em;
}

.authorDetails {
  letter-spacing: 0.01rem;
  font-weight: 300;
}

.Blog a {
  color: white;
}

.Blog a:hover {
  color: #03d694;
  text-decoration: none;
}

.section-title-service {
  text-align: left;
  margin-left: 50px;
}

.carousel-caption {
  top: 40% !important;
}

.serv-table {
  background-color: #f9f9fa;
}

.service-table {
  background-color: white;
  box-shadow: 0px 45px 130px -20px rgba(0,0,0,0.25);
}

.contain {
  padding: 30px;
}

.col-sm-3 {
  padding: 20px;
}

td {
  text-align: left;
}

th {
  text-align: left;
}
@media only screen and (max-width: 768px) {
  .serv-table {
    display: none;
  }

  .mobile-caption {
    top: 15% !important;
  }
}

.bg1 {
  background-image: linear-gradient(
      180deg,
      rgba(0, 58, 91, 0.65) 0%,
      rgba(41, 196, 169, 0.7) 100%
    );
    
  color: white;
  /* width: 100%; */
  height: 0vh;
  background-size: cover;
  background-position: 50% 20%;
  background-repeat: no-repeat;
}
.bg1 h3{
  background-color: var(--color-primary);
}
.contain {
  background-color: #f9f9fa;
  /* padding: 30px; */
  /* border-radius: 10px; */
}

.serv1-heading {
  font-size: 4em;
}

@media only screen and (max-width: 768px) {
  .serv1-heading {
    font-size: 2em;
  }
}
.tao .section-title h2::after{
  content: '';
  position: absolute;
  display: block;
  width: 200px;
  height: 3px;
  background:var(--color-primary);
  bottom: 0;
  left: calc(50% - 100px);
}
.tao .section-title h2{
  font-size: 2.4rem;
  /* font-weight: 600; */
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
}
.shadow-title{
  text-align: center;
  padding: 30px 0;
  position: relative;
}
.shadow-title span {
  position: absolute;
  top: 30px;
  color: #f0f1f3;
  left: 0;
  right: 0;
  z-index: 1;
  font-weight: 700;
  font-size: 42px;
  text-transform: uppercase;
  line-height: 0;
}
.shadow-title h2 {
  font-size: 32px;
  font-weight: 700;
  text-transform: uppercase;
  text-shadow: 0 13.36px 8.896px#5f687b,0 -2px 1px #fff;
  margin-bottom: 20px;
  padding-bottom: 0;
  color: #5f687b;
  position: relative;
  z-index: 2;
}
.bg2 {
  background-image: linear-gradient(
      180deg,
      rgba(0, 58, 91, 0.65) 0%,
      rgba(41, 196, 169, 0.7) 100%
    ),
    url("https://images.pond5.com/businessman-talking-business-phone-holding-footage-088258445_prevstill.jpeg");
  color: white;
  /* width: 100%; */
  height: 50vh;
  background-size: cover;
  background-position: 50% 20%;
  background-repeat: no-repeat;
}

.contain {
  padding: 30px;
  border-radius: 10px;
}

.serv2-heading {
  font-size: 4em;
}

.list-mwa-desc {
  background-color: aliceblue;
  text-align: left;
  padding-left: 30px;
  padding-right: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 18px;
}

.fa-check-circle {
  color: #16df7e;
  size: 0.5em;
  padding: 8px;
}

li {
  line-height: 2;
  text-align: left;
}

.ul-margin {
  margin-left: 120px;
}
@media only screen and (max-width: 768px) {
  .serv2-heading {
    font-size: 2em;
  }

  .ul-margin {
    margin-left: 0px;
  }
}

.bg3 {
  background-image: linear-gradient(
      180deg,
      rgba(0, 58, 91, 0.65) 0%,
      rgba(41, 196, 169, 0.7) 100%
    ),
    url("https://ak.picdn.net/shutterstock/videos/1630318/thumb/1.jpg");
  color: white;
  /* width: 100%; */
  height: 50vh;
  background-size: cover;
  background-position: 50% 20%;
  background-repeat: no-repeat;
}

.contain {
  padding: 30px;
  border-radius: 10px;
}

.serv3-heading {
  font-size: 4em;
}

.list-tra-desc {
  background-color: aliceblue;
  text-align: left;
  padding-left: 30px;
  padding-right: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 18px;
}

@media only screen and (max-width: 768px) {
  .serv3-heading {
    font-size: 2em;
  }
}

.bg4 {
  background-image: linear-gradient(
      180deg,
      rgba(0, 58, 91, 0.65) 0%,
      rgba(41, 196, 169, 0.7) 100%
    ),
    url("https://ak.picdn.net/shutterstock/videos/1630318/thumb/1.jpg");
  color: white;
  /* width: 100%; */
  height: 50vh;
  background-size: cover;
  background-position: 50% 20%;
  background-repeat: no-repeat;
}

.contain {
  padding: 30px;
  border-radius: 10px;
}

.serv4-heading {
  font-size: 4em;
}

tta-bg-img {
  height: 200rem;
  width: 500rem;
}
@media only screen and (max-width: 768px) {
  .serv4-heading {
    font-size: 2em;
  }
}

.collapse-faq[aria-expanded=true] .fa-chevron-down{
    display: none;
}
.collapse-faq[aria-expanded=false] .fa-chevron-down{
    display: inline-block;
}
.collapse-faq[aria-expanded=true] .fa-chevron-up{
    display: inline-block;
}
.collapse-faq[aria-expanded=false] .fa-chevron-up{
    display: none;
}
.fa-chevron-up,.fa-chevron-down{
    margin-right: 1rem;
    font-size: medium;
    color: var(--color-primary);
}
.collapse-faq {
    color:black;
}
.collapse-faq h5{
    float: left;
    font-size: .9rem;
}
.collapse-faq:hover{
    color:gray;
    text-decoration: none;
}
.collapse.faq,.collapsing.faq .card-body{
    text-align: left;
}
.bg-color {
  background-image: url(/static/media/career_new_bg.18f5e9c5.jpg);
  color: white;
  /* width: 100%; */
  height: 30vh;
  background-size: cover;
  background-position: 50% 20%;
  background-repeat: no-repeat;
}

.description {
  text-align: left;
  margin-right: 50px;
}

.career-heading {
  font-size: 3.5em;
}

.Career p {
  letter-spacing: 0.09rem;
}

.Career h4 {
  letter-spacing: 0.1rem;
}

@media only screen and (max-width: 768px) {
  .career-heading {
    font-size: 2.5em;
  }
}
.Career .quote{
  font-size: 16px;
}
.jobscard h2 {
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 1px;
    font-size: 26px;
    color: #333;
    margin: 10px 0 10px;
}
.jobscard .apply{
    background-color: white;
    color:var(--color-secondary);
    margin-right: 10px;
}
.jobscard .apply:hover{
    color: rgba(32, 107, 251, 0.45);
}
@media (max-width:767px){
    .jobscard .apply{
        display: none;
    }
}
.jobscard .location,.depart{
    color:#6d6e71;
    font-size: 14px;
    text-align: start;
    margin-bottom: 0;
}
.jobscard .end-line{
    width: 100%;
    height: 1px;
    background-color: #dadada;
}
.form-box-contactus {
  box-shadow: 0 5px 18px 3px rgba(0, 0, 0, 0.1);
}
.contactus {
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 0.8)
    ),
    url(/static/media/contactus.a286bacb.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 1;
  /* padding: 30px 15px 0; */
  color: white;
  min-height: 50vh;
  padding-top: 1rem;
  padding-bottom: 1rem;
  /* font-size: x-small; */
}
.contactus a {
  text-decoration: none;
  margin-left: 5px;
  color: var(--color-secondary);
}
.contactus a:hover {
  text-decoration: none;
}
.contactus .fas {
  font-size: unset;
}
.contactus-ele {
  flex-flow: column nowrap;
  text-align: left;
  padding-left: 1rem;
  /* line-height: rem; */
}
.contactus i {
  padding-top: 6px;
}
@media (max-width: 766px) {
  .contactus {
    margin-left: 15px;
    margin-right: 15px;
    /* margin-top: 20px; */
  }
}
.contactus-par-ele {
  margin-bottom: 1rem;
  margin-top: 0.5rem;
}
.contactus-main .section-title h2 {
  font-size: 2.4rem;
  font-weight: 500;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
  color: black;
}
.contactus-main .section-title h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 50px;
  height: 3px;
  background: var(--color-primary);
  bottom: 0;
  left: calc(50% - 25px);
}

.contactus-main .jumbotron {
  background-color: #f9f9fa;
}

.social-footer-icons {
  color: var(--color-primary);
  padding-right: 20%;
  padding-left: 20%;
}
.social-footer-icons li {
  display: inline-block;
  margin: 5px;
  color: white;
}
.social-footer-icons i {
  color: white;
  font-size: large;
}
.social-footer {
  /* background-color: #2b3152; */
  border-top: 0.5px solid rgb(199, 199, 199);
  /* width: 40%; */
}
.contactus-main hr {
  height: 2px;
  width: 40%;
  border-width: 0;
  color: gray;
  background-color: var(--color-secondary);
}

.contactus-main,
.form-control {
  border-radius: 0rem;
  font-weight: 300;
}

.contactus-main,
.form-control:focus {
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
  border-color: #16df7e;
}

.input-group-prepend .input-group-text {
  color: #16df7e;
}

.remember input {
  width: 20px;
  height: 20px;
  margin-left: 15px;
  margin-right: 5px;
}
.Bar,
.Progress {
  border-radius: 361px;
}
.welcome-msg {
  margin-top: 10px;
  margin-bottom: 10px;
  color: var(--color-primary);
  text-transform: uppercase;
  text-align: left;
  text-align: initial;
  font-weight: 600;
  font-size: 12px;
}
.getting-started h2 {
  color: rgb(57, 55, 55);
  text-transform: none;
  text-transform: initial;
  text-align: left;
  text-align: initial;
  font-weight: 600;
  font-size: 36px;
  line-height: normal;
  display: block;
}
.getting-started .form-group .bar {
  position: relative;
  display: block;
  width: 100%;
}
.getting-started .form-group .bar:before {
  content: "";
  height: 2px;
  width: 0;
  bottom: 0;
  position: absolute;
  background: var(--color-primary);
  transition: all 0.3s ease;
  left: 0;
}
.getting-started input:focus {
  outline: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.getting-started input:focus ~ .bar::before {
  width: 100%;
}
.getting-started .input-field {
  background-color: #f8fafc;
  padding: 10px;
  width: 100%;
  border-radius: 4px;
  border: none;
}

.fa-arrow-circle-right {
  color: #16df7e;
  padding: 8px;
  font-size: 1.5rem;
}

.sign-up li {
  font-size: 15px;
  font-weight: 450;
  line-height: 3.5;
}

@media only screen and (max-width: 768px) {
  .fa-arrow-circle-right {
    font-size: 1.25rem;
  }

  .sign-up li {
    line-height: 2.25;
  }
}
.getting-started a {
  font-weight: 500;
}
.getting-started a:hover {
  border-bottom: 0.13rem solid;
  text-decoration: none;
}

.invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.text-danger {
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
     -moz-text-size-adjust: 100%;
      -ms-text-size-adjust: 100%;
          text-size-adjust: 100%;
  margin-top: 4px;
  text-align: left;
  margin-left: 15px;
}

.rnc {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 255px;
    background-color: #eee;
    border-radius: 6px;
    padding: 10px;
    box-sizing: border-box;
  }
  
  .rnc-row {
    display: flex;
    align-items: stretch;
    margin: 0 0 10px 0;
  }
  
  .rnc-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 0 0 10px;
  }
  
  .rnc-canvas {
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 4px;
  }
  
  .rnc-button {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    background: #fff;
    color: inherit;
    border: none;
    padding: 0;
    width: 25px;
    height: 20px;
    box-sizing: border-box;
    border-radius: 4px;
  }
  
  .rnc-button svg {
    width: 1em;
    height: 1em;
    fill: currentColor;
  }
  
  .rnc-input {
    border: none;
    padding: 10px 6px;
    font-size: inherit;
    font-family: inherit;
  }
  
.jobdesc .heading{
    /* border-bottom: 1px solid #dadce0; */
    padding-bottom: 10px;
    padding-top: 20px;
    font-weight: 700;
    font-size: 48px;
}
.jobdesc{
    background-color: #f2f2f2;
    min-height: 93vh;
    text-align: start;
}
.backlink{
    padding-top: 20px;
    color: #004dff;
    
    font-weight: 600;
}
.backlink a:hover{
    border-bottom:.13rem solid #004dff;
    text-decoration: none;
}
.jobdesc .small-info{
    word-break: break-word;
    font-size: .8rem;
    color: #000;
    display: inline;
    font-weight: bold;
}
.jobdesc .desc{
    background-color: white;
    padding-top: 20px;
    padding-bottom: 20px;
}
