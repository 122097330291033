.bg1 {
  background-image: linear-gradient(
      180deg,
      rgba(0, 58, 91, 0.65) 0%,
      rgba(41, 196, 169, 0.7) 100%
    );
    
  color: white;
  /* width: 100%; */
  height: 0vh;
  background-size: cover;
  background-position: 50% 20%;
  background-repeat: no-repeat;
}
.bg1 h3{
  background-color: var(--color-primary);
}
.contain {
  background-color: #f9f9fa;
  /* padding: 30px; */
  /* border-radius: 10px; */
}

.serv1-heading {
  font-size: 4em;
}

@media only screen and (max-width: 768px) {
  .serv1-heading {
    font-size: 2em;
  }
}
.tao .section-title h2::after{
  content: '';
  position: absolute;
  display: block;
  width: 200px;
  height: 3px;
  background:var(--color-primary);
  bottom: 0;
  left: calc(50% - 100px);
}
.tao .section-title h2{
  font-size: 2.4rem;
  /* font-weight: 600; */
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
}
.shadow-title{
  text-align: center;
  padding: 30px 0;
  position: relative;
}
.shadow-title span {
  position: absolute;
  top: 30px;
  color: #f0f1f3;
  left: 0;
  right: 0;
  z-index: 1;
  font-weight: 700;
  font-size: 42px;
  text-transform: uppercase;
  line-height: 0;
}
.shadow-title h2 {
  font-size: 32px;
  font-weight: 700;
  text-transform: uppercase;
  text-shadow: 0 13.36px 8.896px#5f687b,0 -2px 1px #fff;
  margin-bottom: 20px;
  padding-bottom: 0;
  color: #5f687b;
  position: relative;
  z-index: 2;
}