.input-group-prepend .input-group-text {
  color: #16df7e;
}

.remember input {
  width: 20px;
  height: 20px;
  margin-left: 15px;
  margin-right: 5px;
}
.Bar,
.Progress {
  border-radius: 361px;
}
.welcome-msg {
  margin-top: 10px;
  margin-bottom: 10px;
  color: var(--color-primary);
  text-transform: uppercase;
  text-align: initial;
  font-weight: 600;
  font-size: 12px;
}
.getting-started h2 {
  color: rgb(57, 55, 55);
  text-transform: initial;
  text-align: initial;
  font-weight: 600;
  font-size: 36px;
  line-height: normal;
  display: block;
}
.getting-started .form-group .bar {
  position: relative;
  display: block;
  width: 100%;
}
.getting-started .form-group .bar:before {
  content: "";
  height: 2px;
  width: 0;
  bottom: 0;
  position: absolute;
  background: var(--color-primary);
  transition: all 0.3s ease;
  left: 0;
}
.getting-started input:focus {
  outline: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.getting-started input:focus ~ .bar::before {
  width: 100%;
}
.getting-started .input-field {
  background-color: #f8fafc;
  padding: 10px;
  width: 100%;
  border-radius: 4px;
  border: none;
}

.fa-arrow-circle-right {
  color: #16df7e;
  padding: 8px;
  font-size: 1.5rem;
}

.sign-up li {
  font-size: 15px;
  font-weight: 450;
  line-height: 3.5;
}

@media only screen and (max-width: 768px) {
  .fa-arrow-circle-right {
    font-size: 1.25rem;
  }

  .sign-up li {
    line-height: 2.25;
  }
}
.getting-started a {
  font-weight: 500;
}
.getting-started a:hover {
  border-bottom: 0.13rem solid;
  text-decoration: none;
}

.invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.text-danger {
  line-height: 1.5;
  text-size-adjust: 100%;
  margin-top: 4px;
  text-align: left;
  margin-left: 15px;
}
