.section-title-service {
  text-align: left;
  margin-left: 50px;
}

.carousel-caption {
  top: 40% !important;
}

.serv-table {
  background-color: #f9f9fa;
}

.service-table {
  background-color: white;
  box-shadow: 0px 45px 130px -20px rgba(0,0,0,0.25);
}

.contain {
  padding: 30px;
}

.col-sm-3 {
  padding: 20px;
}

td {
  text-align: left;
}

th {
  text-align: left;
}
@media only screen and (max-width: 768px) {
  .serv-table {
    display: none;
  }

  .mobile-caption {
    top: 15% !important;
  }
}
