.jobdesc .heading{
    /* border-bottom: 1px solid #dadce0; */
    padding-bottom: 10px;
    padding-top: 20px;
    font-weight: 700;
    font-size: 48px;
}
.jobdesc{
    background-color: #f2f2f2;
    min-height: 93vh;
    text-align: start;
}
.backlink{
    padding-top: 20px;
    color: #004dff;
    
    font-weight: 600;
}
.backlink a:hover{
    border-bottom:.13rem solid #004dff;
    text-decoration: none;
}
.jobdesc .small-info{
    word-break: break-word;
    font-size: .8rem;
    color: #000;
    display: inline;
    font-weight: bold;
}
.jobdesc .desc{
    background-color: white;
    padding-top: 20px;
    padding-bottom: 20px;
}