/* montserrat-regular - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/montserrat-v15-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/montserrat-v15-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/montserrat-v15-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/montserrat-v15-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/montserrat-v15-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/montserrat-v15-latin-regular.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-600 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/montserrat-v15-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/montserrat-v15-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/montserrat-v15-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/montserrat-v15-latin-600.woff') format('woff'), /* Modern Browsers */
       url('../fonts/montserrat-v15-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/montserrat-v15-latin-600.svg#Montserrat') format('svg'); /* Legacy iOS */
}

.navbar-custom {
  width: 100%;
  /* margin:auto; */
  padding-left: 10%;
  padding-right: 10%;
  background-color: white !important;
}

nav,
.dropdown-item {
  font-family: "Montserrat", Helvetica, Arial, Lucida, sans-serif;
  font-size: 14px;
  font-weight: bold !important;
  letter-spacing: 1px;
}
.get-started-button {
  float: right;
  background-color: #ef692b;
  color: white;
  border-radius: 8px;
  border-bottom: 4px #ba6036 solid;
  text-align: center;
  outline: none;
  border-top: none;
  border-left: none;
  border-right: none;
  font-size: 15px;
  font-weight: 600;
  padding-top: 10px;
  margin-left: 8.5px;
}
.get-started-button:hover {
  background-color: #ef7c46;
  border-bottom: 4px #ef7c46 solid;
  text-decoration: none;
  color: white;
  font-size: 15px;
  font-weight: 600;
}
@media screen and (max-width: 768px) {
  .get-started-button {
    float: left;
  }
}

@media screen and (max-width: 990px) {
  .navbar .nav-item:hover .dropdown-menu {
    display: block;
    transition: ease-in-out 0.2s;
  }
}
@media screen and (max-width: 990px) {
  .navbar .nav-item .dropdown-menu {
    border: none;
  }
  .navbar .nav-item .dropdown-item {
    padding-left: 20px;
    transform: translateY(0);
  }
}
.navbar .nav-item .dropdown-menu {
  margin-top: 0;
}

.dropdown-item.active,
.dropdown-item:active {
  text-decoration: none;
  font-weight: bold;
  background-color: white;
}
