.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.container-fluid {
  padding-left: 0ch;
  position: relative;
  padding-right: 0ch;
  margin-left: 0ch;
  margin-right: 0ch;
}
.connect-H {
  background-image: linear-gradient(
      180deg,
      rgba(0, 58, 91, 0.65) 0%,
      rgba(41, 196, 169, 0.7) 100%
    ),
    url(../images/homeBackground.jpg) !important;
  color: white;
  /* width: 100%; */
  height: 80vh;
  background-size: cover;
  background-position: 50% 20%;
  background-repeat: no-repeat;
}

.btn-primary:hover {
  box-shadow: 0 8px 28px rgba(32, 107, 251, 0.45);
}

.btn-primary:focus {
  box-shadow: 0 8px 28px rgba(32, 107, 251, 0.45);
}
.btn-primary:not(:disabled):not(.disabled):active:focus {
  box-shadow: 0 8px 28px rgba(32, 107, 251, 0.45);
}

.btn-primary:disabled {
  box-shadow: none;
}

.col-sm-3 {
  padding: 10px;
}

.col-sm-4 {
  padding: 20px;
}

.col-sm-6 {
  padding: 20px;
}

.svg-container {
  padding: 15px;
}
.form-box {
  margin: auto;
  border: white;
  background-color: white;
  border-radius: 5px;
  padding-top: 1rem;
  padding-bottom: 1rem;
  box-shadow: 0 5px 18px 3px rgba(0, 0, 0, 0.1);
  /* box-shadow: 2px 2px 2px 2px grey; */
  /* padding-bottom: 50px; */
}

.form-control {
  width: 100%;

  /* float: center; */
  /* background-color: LightGrey; */
}

.form-group {
  padding-top: 8px;
  padding-bottom: 8px;
}

.home-form-head {
  text-align: left;
  border-left-style: solid;
  border-left-color: var(--color-primary);
  border-left-width: 5px;
  padding-left: 10px;
}

.home-form-content {
  text-align: left;
  padding: 20px;
}

@media only screen and (max-width: 768px) {
  .form-control {
    width: 100%;
  }

  .home-bg-content {
    font-size: 15px;
  }

  .home-slider .carousel-item .carousel-caption {
    top: 20% !important;
  }

  .home-slider .carousel-item .carousel-caption h2 {
    font-size: 24px;
  }

  .home-slider .carousel-item .carousel-caption p {
    font-size: 1rem;
  }
}

.home-slider .carousel-item::before {
  content: "";
  background-color: rgba(255, 255, 255, 0.6);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}
.home-slider .carousel-item span {
  color: var(--color-primary);
}
.home-slider .carousel-item h2,
p {
  color: black;
}
.home-slider .carousel-item h2 {
  font-size: 48px;
}
.home-slider .carousel-item h2:hover {
  color: black;
}
.home-slider .carousel-item p {
  font-size: 1.25rem;
}
.home-slider .carousel-indicators li {
  /* cursor: pointer; */
  background: var(--color-primary);
  width: 10px;
  height: 10px;
  border-radius: 100%;
  border-top: unset;
  border-bottom: unset;
}
.features-home i {
  color: var(--color-primary);
}
.icon-box a {
  color: #5f687b;
  text-transform: capitalize;
}
.icon-box a:hover {
  color: var(--color-primary);
  text-decoration: unset;
}
.icon-box h3 {
  font-weight: 500;
  margin-top: 15px;
  font-size: 20px;
}
.icon-box {
  padding: 40px 30px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  background: #fff;
  transition: all ease-in-out 0.3s;
  height: 100%;
}
.features-home {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.icon-box p {
  color: #545454;
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}
.counters span {
  font-size: 2.5rem;
  /* display: block; */
  color: var(--color-primary);
  font-weight: 600;
}
.counters {
  margin-top: 1rem;
}
.counters p {
  padding: 0;
  margin: 0 0 20px 0;
  /* font-family: "Raleway", sans-serif; */
  font-size: 14px;
  font-weight: 600;
}
.services-home .section-title {
  text-align: center;
  padding-bottom: 30px;
}
.services-home .section-title h2 {
  font-size: 2.4rem;
  font-weight: 600;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
}
.services-home {
  background-color: #f9f9fa;
}
p {
  line-height: 28px;
  margin-bottom: 15px;
  color: #666;
}
.services-home .section-title h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 50px;
  height: 3px;
  background: var(--color-primary);
  bottom: 0;
  left: calc(50% - 25px);
}

.underlined-subheading h2 {
  font-size: 2.4rem;
  font-weight: 500;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
}

.underlined-subheading h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 50px;
  height: 3px;
  background: var(--color-primary);
  bottom: 0;
  left: calc(50% - 25px);
}
.btn-primary {
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 8px 30px 10px 30px;
  border-radius: 4px;
  transition: 0.5s;
  color: #fff;
  background: #16df7e;
  box-shadow: 0 8px 28px rgba(22, 223, 126, 0.45);
  border: none;
}
/*Counter animation */

/* @property --num {
  syntax: '<integer>';
  initial-value: 0;
  inherits: false;
}

.counters span{
  animation: counter 5s 1 alternate ease-in-out;
  counter-reset: num var(--num);
  animation-fill-mode: forwards;
}
.counters span::after {
  content: counter(num);
}
.counters span::before{
  --data-content:attr(data-content);
}
@keyframes counter {
  from {
    --num: 0;
  }
  to {
    --num: var(--data-content);
  }
} */
