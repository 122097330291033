.bg-color {
  background-image: url('../images/career_new_bg.jpg');
  color: white;
  /* width: 100%; */
  height: 30vh;
  background-size: cover;
  background-position: 50% 20%;
  background-repeat: no-repeat;
}

.description {
  text-align: left;
  margin-right: 50px;
}

.career-heading {
  font-size: 3.5em;
}

.Career p {
  letter-spacing: 0.09rem;
}

.Career h4 {
  letter-spacing: 0.1rem;
}

@media only screen and (max-width: 768px) {
  .career-heading {
    font-size: 2.5em;
  }
}
.Career .quote{
  font-size: 16px;
}