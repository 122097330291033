.bg4 {
  background-image: linear-gradient(
      180deg,
      rgba(0, 58, 91, 0.65) 0%,
      rgba(41, 196, 169, 0.7) 100%
    ),
    url("https://ak.picdn.net/shutterstock/videos/1630318/thumb/1.jpg");
  color: white;
  /* width: 100%; */
  height: 50vh;
  background-size: cover;
  background-position: 50% 20%;
  background-repeat: no-repeat;
}

.contain {
  padding: 30px;
  border-radius: 10px;
}

.serv4-heading {
  font-size: 4em;
}

tta-bg-img {
  height: 200rem;
  width: 500rem;
}
@media only screen and (max-width: 768px) {
  .serv4-heading {
    font-size: 2em;
  }
}
