.carousel-control-prev {
  color: rgb(122, 120, 120);
  transition: all 0.3s ease-in-out;
}
.carousel-control-next:active {
  color: rgb(122, 120, 120);
  transition: all 0.3s ease-in-out;
}
.carousel-control-prev:hover {
  color: var(--color-secondary);
}
.carousel-control-next:hover {
  color: var(--color-secondary);
}
.carousel-control-next {
  color: rgb(122, 120, 120);
  transition: all 0.3s ease-in-out;
}
.fas {
  font-weight: 900;
  font-size: 2rem;
}
.carousel-caption {
  top: 40% !important;
}
.carousel-item img {
  width: 100%;
  height: 60vh;
  object-fit: cover;
}
.carousel-item {
  flex: 1 0 24rem;
}
.people a {
  text-decoration: none;
  font-size: 16px;
  color: #ffffff;
  background: #1a54e7;
  padding: 10px 25px;
  font-weight: 600;
  transition: 0.3s;
  text-transform: capitalize;
}

.caption-footer {
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
@media only screen and (max-width: 600px) {
  .caption-footer {
    display: none;
  }
}
.slider p {
  color: white;
  font-size: 14px;
}
.slider a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}
.carousel-caption h2 {
  cursor: pointer;
}
.carousel-caption h2:hover {
  color: var(--color-secondary);
}

.blog-wrapper {
  position: relative;
  padding: 15px;
}
.blog-img img {
  width: 100%;
  height: 20.5rem;
  object-fit: cover;
}
.blog-content h3 a {
  letter-spacing: 0.02em;
  text-decoration: none;
  color: #000000;
  font-size: 20px;
  padding-top: 20px;
  display: block;
}
.blog-wrapper .tag-1 a {
  bottom: 40%;
  left: 0;
}
.tag-1 a {
  padding: 7px 21px;
  background: var(--color-secondary);
  text-decoration: none;
  color: #ffffff;
  font-weight: 600;
  position: absolute;
  bottom: 40%;
  left: 0;
  font-size: 14px;
  text-transform: capitalize;
}
.slider-date a {
  text-decoration: none;
  text-transform: capitalize;
  font-weight: 600;
  color: #505050;
}

.fa-ul {
  margin-left: 0em;
}

.authorDetails {
  letter-spacing: 0.01rem;
  font-weight: 300;
}

.Blog a {
  color: white;
}

.Blog a:hover {
  color: #03d694;
  text-decoration: none;
}
