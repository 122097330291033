.collapse-faq[aria-expanded=true] .fa-chevron-down{
    display: none;
}
.collapse-faq[aria-expanded=false] .fa-chevron-down{
    display: inline-block;
}
.collapse-faq[aria-expanded=true] .fa-chevron-up{
    display: inline-block;
}
.collapse-faq[aria-expanded=false] .fa-chevron-up{
    display: none;
}
.fa-chevron-up,.fa-chevron-down{
    margin-right: 1rem;
    font-size: medium;
    color: var(--color-primary);
}
.collapse-faq {
    color:black;
}
.collapse-faq h5{
    float: left;
    font-size: .9rem;
}
.collapse-faq:hover{
    color:gray;
    text-decoration: none;
}
.collapse.faq,.collapsing.faq .card-body{
    text-align: left;
}