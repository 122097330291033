.centered{
    position: absolute;
    top: 50%; left: 50%;
    transform: translate(-50%,-50%);
}
p{
    /* font-family: 'Open Sans',Helvetica,Arial,Lucida,sans-serif; */
    font-weight: 400;
    line-height:1.8em;

}
custom-text{
    width: 60%;
    text-align: center;
    margin: auto;
}
.aboutus-H{
    background-image:linear-gradient(180deg,rgba(0,58,91,0.65) 0%,rgba(41,196,169,0.7) 100%),url(../icons/aboutus_bg.jpg)!important;
}
.our-team{
    background-image:linear-gradient(180deg,rgba(0,58,91,0.65) 0%,rgba(41,196,169,0.7) 100%),url(../icons/our-team.jpg)!important;
}
.faq-bg{
    background-image:linear-gradient(180deg,rgba(0,58,91,0.65) 0%,rgba(41,196,169,0.7) 100%),url(../images/faq_bg.jpg)!important;
}
.contactus-bg{
    background-image:linear-gradient(180deg,rgba(0,58,91,0.65) 0%,rgba(41,196,169,0.7) 100%),url(../images/contactus-bg.jpg)!important;
}
.aboutus-H,.our-team,.faq-bg,.contactus-bg{
    color:white;
    /* width: 100%; */
    height: 50vh;
    background-size: cover;
    background-position: 50% 20%;
    background-repeat: no-repeat;
}
.container-fluid{
    padding-left: 0ch;
    position: relative;
    padding-right: 0ch;
    margin-left: 0ch;
    margin-right: 0ch;
}
.subheading{
    margin-top: 20px;
    font-family: 'Open Sans',Helvetica,Arial,Lucida,sans-serif;
    font-weight: 300;
    /* line-height:1.8em; */
    width: 80%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}
